var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "getArea-component" },
    [
      _c(
        "el-select",
        {
          attrs: { "value-key": "regionCode", placeholder: "请选择省" },
          on: { change: _vm.changeProvince },
          model: {
            value: _vm.province,
            callback: function ($$v) {
              _vm.province = $$v
            },
            expression: "province",
          },
        },
        _vm._l(_vm.pca.province, function (item) {
          return _c("el-option", {
            key: item.regionCode,
            attrs: { label: item.regionName, value: item },
          })
        }),
        1
      ),
      _c(
        "el-select",
        {
          attrs: { "value-key": "regionCode", placeholder: "请选择市" },
          on: { change: _vm.changeCity },
          model: {
            value: _vm.city,
            callback: function ($$v) {
              _vm.city = $$v
            },
            expression: "city",
          },
        },
        _vm._l(_vm.pca.city, function (item) {
          return _c("el-option", {
            key: item.regionCode,
            attrs: { label: item.regionName, value: item },
          })
        }),
        1
      ),
      _c(
        "el-select",
        {
          attrs: { "value-key": "regionCode", placeholder: "请选择区" },
          on: { change: _vm.changeArea },
          model: {
            value: _vm.area,
            callback: function ($$v) {
              _vm.area = $$v
            },
            expression: "area",
          },
        },
        _vm._l(_vm.pca.area, function (item) {
          return _c("el-option", {
            key: item.regionCode,
            attrs: { label: item.regionName, value: item },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }