// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.toBeShipped-component .header .el-checkbox {\n  margin-right: 10px;\n}\n.toBeShipped-component .header .el-checkbox__label {\n  display: none !important;\n}\n.toBeShipped-component .el-table__row .check .cell {\n  display: none;\n}\n.formSender .el-form-item__content {\n  width: 74%;\n}\n.jdBuy {\n  width: auto;\n  min-width: 420px;\n  max-width: 550px;\n}\n.jdBuy .el-message-box__content {\n  max-height: 80vh;\n  overflow-y: auto;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
