<template>
<div class="outer toBeShipped-component">
  <el-tabs v-model="avtivePage" @tab-click="handleClick">
    <el-tab-pane label="待发货订单" name="toBeShipped"></el-tab-pane>
    <el-tab-pane label="已发货订单" name="shipped"></el-tab-pane>
  </el-tabs>

  <el-form ref="form" class="search-forms" inline size="mini" :model="forms" label-width="100px">
    <el-form-item :label="item.label" v-for="(item, index) in statics.formsConfig" :key="index">
      <component :is="item.is" v-model="forms[item.key]" v-bind="item.attrs || {}" v-on="item.on || {}">
        <template v-if="item.children">
          <component :is="child.is" v-for="(child, j) in item.children" :key="j" v-bind="child || {}"></component>
        </template>
      </component>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" plain @click="searchfn">搜索</el-button>
      <el-button @click="resetfn">重置</el-button>
      <el-button v-if="avtivePage === 'shipped'" type="primary" @click="print">打印面单</el-button>
      <template v-else>
        <el-button type="primary" @click="deliverGoods">京东发货</el-button>
        <el-button type="primary" @click="changeDelivery('all')">修改包裹数</el-button>
        <!-- <el-button type="primary" @click="insuredPriceFn('')">设置保价</el-button> -->
        <el-button type="primary" plain @click="senderSettings = true">发件人设置</el-button>
      </template>
    </el-form-item>
  </el-form>

  <el-tabs v-if="avtivePage === 'shipped'" v-model="isPrint" @tab-click="changeShowPrint">
    <el-tab-pane label="全部" name="0"></el-tab-pane>
    <el-tab-pane label="待打印" name="2"></el-tab-pane>
    <el-tab-pane label="已打印" name="1"></el-tab-pane>
  </el-tabs>

  <el-checkbox
    style="margin-bottom: 20px;"
    v-if="list.length" :indeterminate="isIndeterminate" v-model="checkAll" @change="toggleSelection"
  >全选</el-checkbox>


  <div class="content-box" v-loading="listLoad">
    <div class="item-box" v-for="item in list" :key="item.tradeOrderVo.orderNo">
      <div class="list-box">
        <div>
          <i
            class="icon"
            :class="{
              'el-icon-arrow-down': expands[item.tradeOrderVo.orderNo],
              'el-icon-arrow-right': !expands[item.tradeOrderVo.orderNo]
            }"
            @click="handleExpand(item)"
          ></i>

          <span v-if="item.tradeOrderGoodsVoList.length && avtivePage === 'shipped'" style="margin-right: 30px;">
            <span class="title">运单号:</span>
            {{item.tradeOrderGoodsVoList[0].logisticNo}}
          </span>

          <span class="title">订单编号:</span>{{item.tradeOrderVo.orderNo}}
        </div>
        <div style="width: 200px;">
          <span class="title">下单时间:</span>{{item.createTime}}
        </div>
        <div v-if="avtivePage === 'shipped'">
          <span class="title">打印次数:</span>{{item.tradeOrderVo.printNum}}
        </div>
        <!-- <div>京东下单状态:</span>{{item.states}}</div> -->
        <div style="text-align: right;">
          <span class="title">操作:</span> 
          <el-button type="primary" size="mini" @click="lookDetail(item)">查看详情</el-button>
          <template v-if="avtivePage === 'toBeShipped'">
            <!-- <el-button type="primary" size="mini" @click="insuredPriceFn(item)">设置保价</el-button> -->
            <el-button type="primary" size="mini" @click="changeDelivery('single', item)">修改包裹数</el-button>
          </template>
          <template v-else>
            <el-button type="primary" size="mini" @click="toDetails(item)">运单详情</el-button>
            <el-button type="primary" size="mini" @click="cancelWaybill(item)">取消运单</el-button>
          </template>
        </div>
      </div>

      <el-table
        :ref="'table_' + item.tradeOrderVo.orderNo"
        v-show="expands[item.tradeOrderVo.orderNo]"
        :data="item.tradeOrderGoodsVoList" border v-loading="load"
        @selection-change="handleSelectionChange($event, item)"
      >
        <el-table-column
          :class-name="avtivePage === 'shipped' ? 'check' : ''"
          type="selection" width="40" align="center"
        ></el-table-column>
        <el-table-column label="商品信息" width="400">
          <template slot-scope="{row}">
            <div class="info-box">
              <el-image fit="contain" :src="row.goodsImage"></el-image>
              <div class="info">
                <div class="title">{{row.goodsName}}</div>
                <div class="infoId">ID: {{row.goodsId}}</div>
                <div class="specifications">规格: {{row.skuName}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sellCount" label="数量"></el-table-column>
        <el-table-column prop="sellTotal" label="商品总价"></el-table-column>
        <el-table-column prop="discountFee" label="店铺优惠"></el-table-column>
        <el-table-column label="实收金额">
          <template slot-scope="{row}">
            <div class="supplierPayment">
              商家实收: {{row.supplierPayment}}<br>
              平台优惠: {{row.couponFee}}<br>
              活动优惠: {{row.promFee}}<br>
              含配送费: {{row.postFee}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="买家信息">
          <template>
            <div class="buyer">
              <el-image :src="item.tradeOrderVo.headPic"></el-image>
              <div>{{item.tradeOrderVo.nickname}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收件人">
          {{item.tradeOrderVo.receiverName}} <br>
          {{item.tradeOrderVo.mobile || '暂无'}}
        </el-table-column>
      </el-table>
    </div>
    <el-table v-if="!list.length" :data="[]" border>
      <el-table-column align="center" v-for="(item, index) in statics.tableCol" :key="index" :label="item"></el-table-column>
    </el-table>
  </div>
  <el-pagination v-if="usePagAttrs.total" v-bind="usePagAttrs" v-on="pagEvents"></el-pagination>

  <!-- 发件人设置 -->
  <el-dialog custom-class="formSender" title="发件人设置" :visible.sync="senderSettings" @close="closedFormSender" width="500px">
    <el-form ref="formSender" size="mini" :model="formsSender" :rules="rulesSender" label-width="80px">
      <el-form-item label="发件人" prop="sender">
        <el-input v-model="formsSender.sender"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="senderMobile">
        <el-input v-model="formsSender.senderMobile" @input="formsSender.senderMobile = inputNumber(formsSender.senderMobile, 0)"></el-input>
      </el-form-item>
      <el-form-item ref="area" label="发件地区" prop="districtCode">
        <Areas
          v-if="senderSettings"
          :provinces="{regionCode: formsSender.provinceCode, regionName: formsSender.provinceName}"
          :citys="{regionCode: formsSender.cityCode, regionName: formsSender.cityName}"
          :areas="{regionCode: formsSender.districtCode, regionName: formsSender.districtName}"
          @areas="getAreasFn"
        ></Areas>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="formsSender.address"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="safeLoad" @click="safefn">保存</el-button>
        <el-button @click="senderSettings = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

  <!-- 修改包裹数 -->
  <el-dialog custom-class="formSender" title="修改包裹数" :visible.sync="modifyOff" @close="closedModify" width="350px">
    <el-form ref="modify" size="mini" :model="formsModify" label-width="80px">
      <el-form-item v-if="formsModify.type === 'single'" label="订单编号">{{formsModify.orderNo}}</el-form-item>
      <el-form-item label="包裹数">
        <el-input-number v-model="formsModify.num" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="safeModifyfn">保存</el-button>
        <el-button @click="modifyOff = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

  <el-dialog v-if="dialog.isShow" custom-class="formSender" :title="dialog.title" :visible.sync="dialog.isShow"  :width="dialog.width">
    <InsuredPrice ref="InsuredPrice" :infos="curr" v-on="on.insuredPrice || {}"/>
  </el-dialog>

  <!-- 确认发货 -->
  <el-dialog custom-class="subSend" title="确认发货" width="500px" :visible.sync="subSend.show" @closed="closedSend">
    <div class="item" style="margin-bottom: 30px;">
      <div class="label">配送类型</div>
      <div class="content">
        <el-radio-group v-model="subSend.promiseTimeType">
          <el-radio :label="1">特惠送</el-radio>
          <el-radio :label="17">生鲜特惠</el-radio>
          <el-radio :label="16">生鲜特快</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="btns">
      <el-button type="primary" size="mini" :loading="deliverLoad" @click="subSendFn">确认</el-button>
      <el-button size="mini" @click="subSend.show = false">取消</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import _ from 'lodash';
import {tableAttrs, dataConfig, inputNumber, lazy} from '@/utils/common';
import Areas from '@/components/getAreas';
import InsuredPrice from './components/insuredPrice';
import dayjs from 'dayjs';

const formsConfig = [
  {is: 'el-input', label: '运单号', key: 'logisticNo', show: 'shipped'},
  {is: 'el-input', label: '订单编号', key: 'orderNo'},
  {is: 'el-input', label: '商品ID', key: 'goodsId'},
  {is: 'el-input', label: '商品名称', key: 'goodsName'},
  {is: 'el-input', label: '收件人名称', key: 'receiverName'},
  {is: 'el-input', label: '收件人电话', key: 'mobile'},
  /* 下单状态后面有空了再加上去
  {
    is: 'el-select', label: '京东下单状态', key: 'supplierOrderStatus', show: 'toBeShipped',
    attrs: {'style': 'width: 196px'},
    children: Object.keys(states).map(key => ({is: 'el-option', label: states[key], value: key}))
  },
  */
  {is: 'el-date-picker', label: '下单时间', key: 'date', attrs: dataConfig()}
];

const statics = {
  defFormValue: {
    logisticNo: '', orderNo: '', goodsId: '', goodsName: '', receiverName: '', mobile: '', date: []
  },
  formsConfig: formsConfig.filter(item => item.show ? item.show === 'toBeShipped' : true),
  tableCol: ['商品信息', '数量', '商品总价', '店铺优惠', '实收金额', '买家信息', '收件人']
};

const rulesSender = {
  sender: [{required: true, message: '请填写发件人', trigger: 'change'}],
  senderMobile: [{required: true, message: '请填写手机号', trigger: 'change'}],
  districtCode: [{required: true, message: '请选择省市区', trigger: 'blur'}],
  address: [{required: true, message: '请填写详细地址', trigger: 'change'}]
}

export default {
  components: {Areas, InsuredPrice},
  data() {
    this.statics = statics;
    this.rulesSender = rulesSender;

    return {
      avtivePage: 'toBeShipped', currPage: 'toBeShipped',
      // 搜索表单相关
      forms: _.cloneDeep(statics.defFormValue),
      // 全选状态相关
      isIndeterminate: false, checkAll: false, checkedObj: {}, checked: [], totalLen: 0,
      // 订单内容相关
      ...tableAttrs(this, {usePagAttrs: {'page-size': 10, 'page-sizes': [10, 20, 50]}}),
      // 发件人设置相关
      senderSettings: false,
      sender: {}, // 发件人信息
      formsSender: {
        sender: '', senderMobile: '',
        provinceCode: '', provinceName: '',
        cityCode: '', cityName: '',
        districtCode: '', districtName: '',
        address: ''
      },
      // 修改包裹数相关
      modifyOff: false,
      formsModify: {type: '', orderNo: '', num: 1, item: null},
      // 保价
      formsInsuredPrice: {type: '', orderNo: '', price: ''},
      priceErr: false,
      curr: {},

      expands: {},
      supplierId: JSON.parse(localStorage.getItem('userInfo')).suppliersId, // 商家id
      listLoad: false, deliverLoad: false, safeLoad: false,

      isPrint: '0', currPrint: '0',

      dialog: {isShow: false, title: '', width: '450px'},
      on: {
        insuredPrice: {
          cancel: () => {
            this.dialog.isShow = false;
          }
        }
      },

      subSend: {
        show: false,
        promiseTimeType: 1
      }
    };
  },
  async mounted() {
    //this.getJdInfo();
    await this.getData();
    this.getDetail();
  },
  watch: {
    checked(newVal) {
      if (newVal.length) {
        if (newVal.length === this.totalLen) {
          this.checkAll = true;
          this.isIndeterminate = false;
        } else {
          this.checkAll = false;
          this.isIndeterminate = true;
        }
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
    }
  },
  methods: {
    inputNumber,
    resetCheck() {
      this.isIndeterminate = false;
      this.checkAll = false;
      this.checkedObj = {};
      this.checked = [];
      this.totalLen = 0;
    },
    changeShowPrint() {
      if (this.currPrint === this.isPrint) return;
      this.resetfn();
    },
    // 设置保价
    insuredPriceFn(item) {
      if (!item) {
        if (!this.validFn(this.checked)) return;
        this.curr.orderNo = '';
        this.curr.orderIds = this.checked.map(item => item.orderId).join(',');
      } else {
        this.curr.orderNo = item.tradeOrderVo.orderNo;
        this.curr.orderIds = item.tradeOrderVo.orderId;
      }
      this.curr.supplierId = this.supplierId;

      this.dialog = {title: '设置保价', width: '450px', isShow: true};
    },
    // 打印面单
    print() {
      if (!this.validFn(this.checked)) return;

      const orderIds = this.checked.map(item => item.orderId).join(',')
      this.$router.push({path: '/nb/order/jd/print', query: {orderIds, supplierId: this.supplierId}});
    },
    // 获取京东物流信息, 好像没有用到的地方
    async getJdInfo() {
      const send = {deliveryId: ''};
      const res = await this.$axios.post(this.$api.jd.delivery_info, send);
    },
    // 京东发货
    deliverGoods() {
      if (!this.validFn(this.checked)) return;
      if (!this.sender.supplierId) {
        this.$message({type: 'error', message: '您尚未设置发件人，请设置后操作'});
        return;
      }

      this.subSend.show = true;
    },
    subSendFn() {
      this.deliverLoad = true;
      lazy.call(this, async () => {
        const send = {
          addressId: this.sender.id, // 发件人地址Id
          supplierId: this.supplierId, // 商家id
          deliveryData: this.checked,
          promiseTimeType: this.subSend.promiseTimeType
        };
        const res = await this.$axios.post(this.$api.jd.delivery, send);
        this.deliverLoad = false;
        if (res.code === 0) {
          this.subSend.show = false;
          this.$alert(res.data, '提示', {
            customClass: 'jdBuy', confirmButtonText: '确定',
            callback: action => {
              this.getData();
            }
          });
        } else {
          this.$message({type: 'error', message: res.msg});
        }
      }, 500);
    },
    closedSend() {
      this.subSend.promiseTimeType = 1;
    },
    // 全选
    toggleSelection() {
      if (this.checkAll) {
        this.list.forEach(item => {
          item.tradeOrderGoodsVoList.forEach(child => {
            this.$refs[`table_${item.tradeOrderVo.orderNo}`][0].toggleRowSelection(child, true);
          });
        });
      } else {
        this.list.forEach(item => {
          item.tradeOrderGoodsVoList.forEach(child => {
            this.$refs[`table_${item.tradeOrderVo.orderNo}`][0].toggleRowSelection(child, false);
          });
        });
      }
    },
    // 多选操作
    handleSelectionChange(v, item) {
      const orderNo = item.tradeOrderVo.orderNo; // 交易订单号

      item.checked.orderNo = orderNo;
      item.checked.orderId =item.tradeOrderVo.orderId;
      item.checked.subOrderIdList = v.map(row => row.subOrderId);
      
      this.checkedObj[orderNo] = item.checked;
      this.checked = Object.values(this.checkedObj).filter(check => check.subOrderIdList.length);
    },
    lookDetail(item) {
      const {href} = this.$router.resolve({path: '/nb/order/detail', query: {orderId: item.tradeOrderVo.orderId}});
      window.open(href, '_blank');
    },
    // 获取发件人信息
    async getDetail() {
      const res = await this.$axios.get(this.$api.jd.detail, {params: {supplierId: this.supplierId}});
      if (res.code === 0) {
        this.sender = res.data || {};
        for (let key in this.formsSender) {
          this.formsSender[key] = this.sender[key];
        }
      }
    },
    // 获取列表数据
    async getData() {
      this.resetCheck();

      const send = {
        currentPage: this.usePagAttrs['current-page'],
        pageSize: this.usePagAttrs['page-size']
      };

      for (let key in this.forms) {
        if (this.forms[key].length) send[key] = this.forms[key]; 
      }

      if (this.avtivePage === 'toBeShipped') { // 待发货
        send.supplierOrderStatus = 6;
        send.isJd = 0;
      } else {
        send.supplierOrderStatus = 3;
        send.isJd = 1;
        send.isPrint = +this.isPrint;
      }

      if (send.date) {
        send.beginTime = send.date ? send.date[0] : '';
        send.endTime = send.date ? send.date[1] : '';
        delete send.date;
      }

      this.listLoad = true;
      const res = await this.$axios.post(this.$api.order.orderList, send);
      this.listLoad = false;
      this.currPage = this.avtivePage;
      this.currPrint = this.isPrint;

      if (res.code === 0) {
        this.list = res.data.records;
        this.usePagAttrs.total = parseInt(res.data.total);

        this.list.forEach(item => {
          if (!item.tradeOrderGoodsVoList) item.tradeOrderGoodsVoList = [];
          item.createTime = dayjs(item.tradeOrderVo.createTime).format('YYYY-MM-DD HH:mm:ss');
          item.checked = {};
          this.$set(this.expands, item.tradeOrderVo.orderNo, true);
          this.totalLen += item.tradeOrderGoodsVoList.length;
        });
      }
    },
    // 展开商品列表
    handleExpand(item) {
      this.expands[item.tradeOrderVo.orderNo] = !this.expands[item.tradeOrderVo.orderNo];
    },
    // 切换顶部标签
    handleClick(tab) {
      if (this.currPage === this.avtivePage) return;
      this.statics.formsConfig = formsConfig.filter(item => item.show ? item.show === this.avtivePage : true);
      this.currPrint = this.isPrint = '0';
      this.resetfn();
    },
    // 取消运单
    cancelWaybill(item) {
      const send = {
        deliveryId: item.tradeOrderGoodsVoList[0].logisticNo || '', // 京东物流单号
        goodsId: 0,
        subOrderIdList: item.tradeOrderGoodsVoList.map(row => row.subOrderId),
        orderId: item.tradeOrderVo.orderId,
        interceptReason: '' // 拦截/取消发货原因
      };

      this.$msgbox({
        title: '提示', dangerouslyUseHTMLString: true, showCancelButton: true,
        message: '是否确认取消该运单?',
        beforeClose: async (action, instance, done) => {
          if (action == 'confirm') {
            instance.confirmButtonLoading = true;
            const res = await this.$axios.post(this.$api.jd.cancel, send);
            instance.confirmButtonLoading = false;
            if (res.code === 0) {
              this.$message.success(`取消成功`);
              done();
              this.getData();
            }
          } else {
            done();
          }
        }
      });
    },
    // 搜索按钮
    searchfn() {
      if (this.listLoad) return;
      this.usePagAttrs['current-page'] = 1;
      this.getData();
    },
    // 重置按钮
    resetfn() {
      if (this.listLoad) return;
      this.forms = _.cloneDeep(statics.defFormValue);
      this.usePagAttrs['current-page'] = 1;
      this.usePagAttrs.total = 0;
      this.getData();
    },
    // 省市区
    getAreasFn({province, city, area}) {
      this.formsSender.provinceCode = province.regionCode;
      this.formsSender.provinceName = province.regionName;

      this.formsSender.cityCode = city.regionCode;
      this.formsSender.cityName = city.regionName;

      this.formsSender.districtCode = area.regionCode;
      this.formsSender.districtName = area.regionName;
      if (this.formsSender.districtCode) this.$refs.area.clearValidate();
    },
    // 发件人设置弹框 关闭的回调
    closedFormSender() {
      if (this.sender.supplierId) {
        for (let key in this.formsSender) {
          this.formsSender[key] = this.sender[key];
        }
      } else {
        this.$refs.formSender.resetFields();
        this.formsSender.provinceCode = '';
        this.formsSender.cityCode = '';
      }
    },
    // 发件人设置弹框 点击保存按钮
    safefn() {
      this.$refs.formSender.validate(async valid => {
        if (valid) {
          const send = {
            supplierId: this.supplierId,
            ...this.formsSender
          };
          this.safeLoad = true;
          const res = await this.$axios.post(this.$api.jd.add, send);
          this.safeLoad = false;
          if (res.code === 0) {
            this.$message.success(`设置成功`);
            this.senderSettings = false;
            this.getDetail();
          } else {
            this.$message.err(res.msg);
          }
        }
      })
    },
    // 点击修改包裹数按钮
    async changeDelivery(type, item) {
      this.formsModify.type = type;

      if (type == 'all') {
        if (!this.validFn(this.checked)) return;
        this.formsModify.item = null;
        this.formsModify.orderNo = '';
        this.formsModify.num = 1;
        this.modifyOff = true;
      } else {
        const orderNo = item.tradeOrderVo.orderNo; // 交易订单号
        const {code, data} = await this.$axios.get(this.$api.jd.packageNum, {params: {orderId: item.tradeOrderVo.orderId}});
        if (code === 0) {
          this.formsModify.item = item;
          this.formsModify.orderNo = orderNo;
          this.formsModify.num = data;
          this.modifyOff = true;
        }
      }
    },
    // 修改包裹数弹框 点击保存按钮
    async safeModifyfn() {
      const send = {packageNum: this.formsModify.num};

      if (this.formsModify.type === 'all') {
        send.orderIds = this.checked.map(item => item.orderId).join(',');
      } else {
        send.orderIds = this.formsModify.item.tradeOrderVo.orderId;
      }

      const {code} = await this.$axios.post(this.$api.jd.changeDelivery, send);
      if (code === 0) {
        this.modifyOff = false;
        this.$message({type: 'success', message: '修改成功'});
      }
    },
    // 运单详情
    toDetails(item) {
      const {logisticCode, logisticName, logisticNo} = item.tradeOrderGoodsVoList[0];
      const {href} = this.$router.resolve({path: '/nb/order/refund/track', query: {logisticCode, logisticName, logisticNo}})
      window.open(href, '_blank');
    },
    closedModify() {
      this.formsModify.type = '';
      this.formsModify.orderNo = '';
      this.formsModify.num = 1;
    },
    validFn(property) {
      if (!property || !property.length) {
        this.$message({type: 'error', message: '请选择订单'});
        return false;
      } else if (property.length > 100) {
        this.$message({type: 'error', message: '最多选择100个订单'});
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>

<style lang="scss">
.toBeShipped-component{
  .header{
    .el-checkbox{margin-right: 10px;}
    .el-checkbox__label{display: none!important;}
  }
  .el-table__row{
    .check .cell{display: none;}
  }
}
.formSender{
  .el-form-item__content{width: 74%;}
}
.jdBuy{
  width: auto; min-width: 420px; max-width: 550px;
  .el-message-box__content{max-height: 80vh; overflow-y: auto;}
}
</style>

<style lang="scss" scoped>
.toBeShipped-component{
  .el-form{margin-bottom: 50px;}
  .item-box{margin-bottom: 50px;}
  .list-box{
    display: flex; justify-content: space-between; margin-bottom: 5px; border: 1px solid #ebeef5;
    line-height: 28px; padding: 12px 16px; font-size: 12px; background: #f5f7fa;
    .icon{line-height: 28px; margin-right: 10px; cursor: pointer;}
    .title{margin-right: 10px;}
  }
  .info-box{
    display: flex; color: #999; text-align: left;
    .el-image{flex-shrink: 0; width: 100px; margin-right: 10px;}
    .info{line-height: 16px;}
    .infoId{margin: 15px 0 10px 0;}
    .title{color: #333;}
  }

  .buyer .el-image{width: 60px; margin-bottom: 10px;}
  .checkAll .el-checkbox{float: left;}
}

.subSend{
  .item{display: flex;}
  .label{width: 80px; text-align: right; margin-right: 20px;}
  .btns{padding-left: 100px;}
}
</style>