var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "insuredPrice-component" },
    [
      _c(
        "el-form",
        {
          ref: "modify",
          attrs: { size: "mini", model: _vm.forms, "label-width": "80px" },
        },
        [
          _vm.infos.orderNo
            ? _c("el-form-item", { attrs: { label: "订单编号" } }, [
                _c("b", [_vm._v(_vm._s(_vm.infos.orderNo))]),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "保价金额" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入0~300000的整数" },
                on: { input: _vm.changePrice },
                model: {
                  value: _vm.forms.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.forms, "price", $$v)
                  },
                  expression: "forms.price",
                },
              }),
              _vm.priceErr
                ? _c("div", { staticClass: "el-form-item__error" }, [
                    _vm._v("请输入大于0小于300000的整数"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "text" }, [
            _c("p", [
              _vm._v(
                "高价值、已损坏物品建议保价，未保价货品最高只赔付6倍运费。"
              ),
            ]),
            _c("p", [
              _vm._v(
                "保价服务说明：京东物流为商家提供保价服务，根据保价金额缴纳相应保费，即可当货物在运输过程中发生损毁或遗失时进行赔付。"
              ),
            ]),
            _c("p", [
              _vm._v(" 货物价值500元及以下，费率2‰，保费最低1元"),
              _c("br"),
              _vm._v(" 货物价值500-1000元，费率2‰，保费最低2元"),
              _c("br"),
              _vm._v(" 货物价值1000元以上，费率3‰，保费最低3元 "),
            ]),
          ]),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.subLoad },
                  on: { click: _vm.submit },
                },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }