<template>
  <div class="getArea-component">
    <el-select
      v-model="province"
      value-key="regionCode"
      placeholder="请选择省"
      @change="changeProvince"
    >
      <el-option
        v-for="item in pca.province"
        :key="item.regionCode"
        :label="item.regionName"
        :value="item"
      ></el-option>
    </el-select>
    <el-select
      v-model="city"
      value-key="regionCode"
      placeholder="请选择市"
      @change="changeCity"
    >
      <el-option
        v-for="item in pca.city"
        :key="item.regionCode"
        :label="item.regionName"
        :value="item"
      ></el-option>
    </el-select>
    <el-select
      v-model="area"
      value-key="regionCode"
      placeholder="请选择区"
      @change="changeArea"
    >
      <el-option
        v-for="item in pca.area"
        :key="item.regionCode"
        :label="item.regionName"
        :value="item"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    provinces: {
      type: Object,
      default() { return {} }
    },
    citys: {
      type: Object,
      default() { return {} }
    },
    areas: {
      type: Object,
      default() { return {} }
    }
  },
  data() {
    return {
      province: this.provinces,
      city: this.citys,
      area: this.areas,
      pca: { province: [], city: [], area: [] }
    };
  },
  mounted() {
    this.getProvince();
  },
  methods: {
    async getProvince() {
      if (this.province.regionCode) {
        Promise.all([
          this.getAreas(),
          this.getAreas(this.province.regionCode),
          this.getAreas(this.city.regionCode)
        ]).then(res => {
          this.pca.province = res[0];
          this.pca.city = res[1];
          this.pca.area = res[2];
        });
      } else {
        this.pca.province = await this.getAreas();
      }
    },
    async changeProvince(item) {
      this.province = item;
      this.city = {}; this.area = {};
      this.pca.city = []; this.pca.area = [];

      this.emits();
      this.pca.city = await this.getAreas(this.province.regionCode);
    },
    async changeCity(item) {
      this.city = item;
      this.area = {}; this.pca.area = [];

      this.emits();
      this.pca.area = await this.getAreas(this.city.regionCode);
    },
    changeArea(item) {
      this.area = item;
      this.emits();
    },
    emits() {
      this.$emit('areas', { province: this.province, city: this.city, area: this.area });
    },
    getAreas(id = 0) {
      return new Promise(async resolve => {
        const res = await this.$axios({
          method: 'get',
          url: this.$api.commonNew.getChildRegion,
          params: { regionCode: id }
        });

        if (res.code === 0) resolve(res.data);
      })
    }
  }
}
</script>
<style lang="scss">
.getArea-component{
  .el-select{
    width: 30%;
    &:nth-of-type(2){margin: 0 17px;}
  }
}
</style>
