var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer toBeShipped-component" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.avtivePage,
            callback: function ($$v) {
              _vm.avtivePage = $$v
            },
            expression: "avtivePage",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "待发货订单", name: "toBeShipped" },
          }),
          _c("el-tab-pane", {
            attrs: { label: "已发货订单", name: "shipped" },
          }),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "search-forms",
          attrs: {
            inline: "",
            size: "mini",
            model: _vm.forms,
            "label-width": "100px",
          },
        },
        [
          _vm._l(_vm.statics.formsConfig, function (item, index) {
            return _c(
              "el-form-item",
              { key: index, attrs: { label: item.label } },
              [
                _c(
                  item.is,
                  _vm._g(
                    _vm._b(
                      {
                        tag: "component",
                        model: {
                          value: _vm.forms[item.key],
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, item.key, $$v)
                          },
                          expression: "forms[item.key]",
                        },
                      },
                      "component",
                      item.attrs || {},
                      false
                    ),
                    item.on || {}
                  ),
                  [
                    item.children
                      ? _vm._l(item.children, function (child, j) {
                          return _c(
                            child.is,
                            _vm._b(
                              { key: j, tag: "component" },
                              "component",
                              child || {},
                              false
                            )
                          )
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.searchfn },
                },
                [_vm._v("搜索")]
              ),
              _c("el-button", { on: { click: _vm.resetfn } }, [_vm._v("重置")]),
              _vm.avtivePage === "shipped"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.print } },
                    [_vm._v("打印面单")]
                  )
                : [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.deliverGoods },
                      },
                      [_vm._v("京东发货")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.changeDelivery("all")
                          },
                        },
                      },
                      [_vm._v("修改包裹数")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            _vm.senderSettings = true
                          },
                        },
                      },
                      [_vm._v("发件人设置")]
                    ),
                  ],
            ],
            2
          ),
        ],
        2
      ),
      _vm.avtivePage === "shipped"
        ? _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeShowPrint },
              model: {
                value: _vm.isPrint,
                callback: function ($$v) {
                  _vm.isPrint = $$v
                },
                expression: "isPrint",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "全部", name: "0" } }),
              _c("el-tab-pane", { attrs: { label: "待打印", name: "2" } }),
              _c("el-tab-pane", { attrs: { label: "已打印", name: "1" } }),
            ],
            1
          )
        : _vm._e(),
      _vm.list.length
        ? _c(
            "el-checkbox",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.toggleSelection },
              model: {
                value: _vm.checkAll,
                callback: function ($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll",
              },
            },
            [_vm._v("全选")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoad,
              expression: "listLoad",
            },
          ],
          staticClass: "content-box",
        },
        [
          _vm._l(_vm.list, function (item) {
            return _c(
              "div",
              { key: item.tradeOrderVo.orderNo, staticClass: "item-box" },
              [
                _c("div", { staticClass: "list-box" }, [
                  _c("div", [
                    _c("i", {
                      staticClass: "icon",
                      class: {
                        "el-icon-arrow-down":
                          _vm.expands[item.tradeOrderVo.orderNo],
                        "el-icon-arrow-right":
                          !_vm.expands[item.tradeOrderVo.orderNo],
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleExpand(item)
                        },
                      },
                    }),
                    item.tradeOrderGoodsVoList.length &&
                    _vm.avtivePage === "shipped"
                      ? _c(
                          "span",
                          { staticStyle: { "margin-right": "30px" } },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("运单号:"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.tradeOrderGoodsVoList[0].logisticNo
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("span", { staticClass: "title" }, [_vm._v("订单编号:")]),
                    _vm._v(_vm._s(item.tradeOrderVo.orderNo) + " "),
                  ]),
                  _c("div", { staticStyle: { width: "200px" } }, [
                    _c("span", { staticClass: "title" }, [_vm._v("下单时间:")]),
                    _vm._v(_vm._s(item.createTime) + " "),
                  ]),
                  _vm.avtivePage === "shipped"
                    ? _c("div", [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("打印次数:"),
                        ]),
                        _vm._v(_vm._s(item.tradeOrderVo.printNum) + " "),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c("span", { staticClass: "title" }, [_vm._v("操作:")]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.lookDetail(item)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                      _vm.avtivePage === "toBeShipped"
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeDelivery("single", item)
                                  },
                                },
                              },
                              [_vm._v("修改包裹数")]
                            ),
                          ]
                        : [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetails(item)
                                  },
                                },
                              },
                              [_vm._v("运单详情")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelWaybill(item)
                                  },
                                },
                              },
                              [_vm._v("取消运单")]
                            ),
                          ],
                    ],
                    2
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.expands[item.tradeOrderVo.orderNo],
                        expression: "expands[item.tradeOrderVo.orderNo]",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.load,
                        expression: "load",
                      },
                    ],
                    ref: "table_" + item.tradeOrderVo.orderNo,
                    refInFor: true,
                    attrs: { data: item.tradeOrderGoodsVoList, border: "" },
                    on: {
                      "selection-change": function ($event) {
                        return _vm.handleSelectionChange($event, item)
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        "class-name":
                          _vm.avtivePage === "shipped" ? "check" : "",
                        type: "selection",
                        width: "40",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "商品信息", width: "400" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "info-box" },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        fit: "contain",
                                        src: row.goodsImage,
                                      },
                                    }),
                                    _c("div", { staticClass: "info" }, [
                                      _c("div", { staticClass: "title" }, [
                                        _vm._v(_vm._s(row.goodsName)),
                                      ]),
                                      _c("div", { staticClass: "infoId" }, [
                                        _vm._v("ID: " + _vm._s(row.goodsId)),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "specifications" },
                                        [_vm._v("规格: " + _vm._s(row.skuName))]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "sellCount", label: "数量" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "sellTotal", label: "商品总价" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "discountFee", label: "店铺优惠" },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "实收金额" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", { staticClass: "supplierPayment" }, [
                                  _vm._v(
                                    " 商家实收: " + _vm._s(row.supplierPayment)
                                  ),
                                  _c("br"),
                                  _vm._v(" 平台优惠: " + _vm._s(row.couponFee)),
                                  _c("br"),
                                  _vm._v(" 活动优惠: " + _vm._s(row.promFee)),
                                  _c("br"),
                                  _vm._v(
                                    " 含配送费: " + _vm._s(row.postFee) + " "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { label: "买家信息" } },
                      [
                        [
                          _c(
                            "div",
                            { staticClass: "buyer" },
                            [
                              _c("el-image", {
                                attrs: { src: item.tradeOrderVo.headPic },
                              }),
                              _c("div", [
                                _vm._v(_vm._s(item.tradeOrderVo.nickname)),
                              ]),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                    _c("el-table-column", { attrs: { label: "收件人" } }, [
                      _vm._v(
                        " " + _vm._s(item.tradeOrderVo.receiverName) + " "
                      ),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(item.tradeOrderVo.mobile || "暂无") + " "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          !_vm.list.length
            ? _c(
                "el-table",
                { attrs: { data: [], border: "" } },
                _vm._l(_vm.statics.tableCol, function (item, index) {
                  return _c("el-table-column", {
                    key: index,
                    attrs: { align: "center", label: item },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.usePagAttrs.total
        ? _c(
            "el-pagination",
            _vm._g(
              _vm._b({}, "el-pagination", _vm.usePagAttrs, false),
              _vm.pagEvents
            )
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "formSender",
            title: "发件人设置",
            visible: _vm.senderSettings,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.senderSettings = $event
            },
            close: _vm.closedFormSender,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formSender",
              attrs: {
                size: "mini",
                model: _vm.formsSender,
                rules: _vm.rulesSender,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发件人", prop: "sender" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formsSender.sender,
                      callback: function ($$v) {
                        _vm.$set(_vm.formsSender, "sender", $$v)
                      },
                      expression: "formsSender.sender",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "senderMobile" } },
                [
                  _c("el-input", {
                    on: {
                      input: function ($event) {
                        _vm.formsSender.senderMobile = _vm.inputNumber(
                          _vm.formsSender.senderMobile,
                          0
                        )
                      },
                    },
                    model: {
                      value: _vm.formsSender.senderMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formsSender, "senderMobile", $$v)
                      },
                      expression: "formsSender.senderMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  ref: "area",
                  attrs: { label: "发件地区", prop: "districtCode" },
                },
                [
                  _vm.senderSettings
                    ? _c("Areas", {
                        attrs: {
                          provinces: {
                            regionCode: _vm.formsSender.provinceCode,
                            regionName: _vm.formsSender.provinceName,
                          },
                          citys: {
                            regionCode: _vm.formsSender.cityCode,
                            regionName: _vm.formsSender.cityName,
                          },
                          areas: {
                            regionCode: _vm.formsSender.districtCode,
                            regionName: _vm.formsSender.districtName,
                          },
                        },
                        on: { areas: _vm.getAreasFn },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "address" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formsSender.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.formsSender, "address", $$v)
                      },
                      expression: "formsSender.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.safeLoad },
                      on: { click: _vm.safefn },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.senderSettings = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "formSender",
            title: "修改包裹数",
            visible: _vm.modifyOff,
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.modifyOff = $event
            },
            close: _vm.closedModify,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "modify",
              attrs: {
                size: "mini",
                model: _vm.formsModify,
                "label-width": "80px",
              },
            },
            [
              _vm.formsModify.type === "single"
                ? _c("el-form-item", { attrs: { label: "订单编号" } }, [
                    _vm._v(_vm._s(_vm.formsModify.orderNo)),
                  ])
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "包裹数" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.formsModify.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.formsModify, "num", $$v)
                      },
                      expression: "formsModify.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.safeModifyfn },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.modifyOff = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialog.isShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                "custom-class": "formSender",
                title: _vm.dialog.title,
                visible: _vm.dialog.isShow,
                width: _vm.dialog.width,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.dialog, "isShow", $event)
                },
              },
            },
            [
              _c(
                "InsuredPrice",
                _vm._g(
                  { ref: "InsuredPrice", attrs: { infos: _vm.curr } },
                  _vm.on.insuredPrice || {}
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "subSend",
            title: "确认发货",
            width: "500px",
            visible: _vm.subSend.show,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.subSend, "show", $event)
            },
            closed: _vm.closedSend,
          },
        },
        [
          _c(
            "div",
            { staticClass: "item", staticStyle: { "margin-bottom": "30px" } },
            [
              _c("div", { staticClass: "label" }, [_vm._v("配送类型")]),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.subSend.promiseTimeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.subSend, "promiseTimeType", $$v)
                        },
                        expression: "subSend.promiseTimeType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("特惠送"),
                      ]),
                      _c("el-radio", { attrs: { label: 17 } }, [
                        _vm._v("生鲜特惠"),
                      ]),
                      _c("el-radio", { attrs: { label: 16 } }, [
                        _vm._v("生鲜特快"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "btns" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.deliverLoad,
                  },
                  on: { click: _vm.subSendFn },
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.subSend.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }