<template>
<div class="insuredPrice-component">
  <el-form ref="modify" size="mini" :model="forms" label-width="80px">
    <el-form-item v-if="infos.orderNo" label="订单编号"><b>{{infos.orderNo}}</b></el-form-item>
    <el-form-item label="保价金额">
      <el-input v-model="forms.price" placeholder="请输入0~300000的整数" @input="changePrice"></el-input>
      <div v-if="priceErr" class="el-form-item__error">请输入大于0小于300000的整数</div>
    </el-form-item>
    <div class="text">
      <p>高价值、已损坏物品建议保价，未保价货品最高只赔付6倍运费。</p>
      <p>保价服务说明：京东物流为商家提供保价服务，根据保价金额缴纳相应保费，即可当货物在运输过程中发生损毁或遗失时进行赔付。</p>
      <p>
        货物价值500元及以下，费率2‰，保费最低1元<br>
        货物价值500-1000元，费率2‰，保费最低2元<br>
        货物价值1000元以上，费率3‰，保费最低3元
      </p>
    </div>
    <el-form-item>
      <el-button type="primary" :loading="subLoad" @click="submit">保存</el-button>
      <el-button @click="cancel">取消</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import {inputNumber, lazy} from '@/utils/common';

export default {
  props: {
    infos: {
      type: Object, default: () => ({})
    }
  },
  components: {},
  data() {
    return {
      forms: {price: ''},
      priceErr: false, subLoad: false
    };
  },
  mounted() {
    if (this.infos.orderNo) this.getDatas();
  },
  methods: {
    changePrice() {
      this.forms.price = inputNumber(this.forms.price, 0);

      if(this.forms.price && this.forms.price > 300000) this.priceErr = true;
      else this.priceErr = false;
    },
    async getDatas() {
      const res = await this.$axios.get(this.$api.jd.valuate, {params: {orderId: this.infos.orderIds}});
      if (res.code === 0) {
        this.forms.price = res.data ? res.data + '' : '';
      }
    },
    submit() {
      if (this.forms.price && this.priceErr) return;

      const send = {
        orderIds: this.infos.orderIds,
        supplierId: this.infos.supplierId,
        valuation: +this.forms.price
      };
      this.subLoad = true;
      lazy.call(this, async () => {
        const {code} = await this.$axios.post(this.$api.jd.addValuation, send);
        this.subLoad = false;
        if (code === 0) {
          this.$message({type: 'success', message: '保存成功'});
          this.cancel();
        }
      }, 300);
    },
    cancel() {
      this.$emit('cancel');
    }
  }
}
</script>
<style lang="scss" scoped>
.insuredPrice-component{
  .text{line-height: 16px; padding: 0 10px 0 13px; font-size: 12px;}
  p{margin-bottom: 10px;}
}
</style>